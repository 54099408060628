<template>
  <div>
    <q-form ref="editForm">
      <!--의견 청취-->
      <c-card title="안전 의견" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <!--제목-->
            <c-text
              required
              :editable="editable"
              label="작성자"
              name="regUserId"
              v-model="data.regUserId">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <!--제목-->
            <c-text
              required
              :editable="editable"
              label="제목"
              name="boardTitle"
              v-model="data.boardTitle">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <!--위치-->
            <c-text
              :editable="editable"
              label="위치"
              name="boardLocation"
              v-model="data.boardLocation">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <!--의견게시일-->
            <c-datepicker
              required
              :editable="editable"
              label="의견게시일"
              name="boardDate"
              v-model="data.boardDate">
            </c-datepicker>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!--개요-->
            <c-textarea
              required
              :editable="editable"
              :rows="4"
              label="개요"
              name="boardContents"
              v-model="data.boardContents">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!--현황 및 문제점-->
            <c-textarea
              :editable="editable"
              :rows="4"
              label="현황 및 문제점"
              name="boardProblem"
              v-model="data.boardProblem">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!--개선방안-->
            <c-textarea
              :editable="editable"
              :rows="4"
              label="개선방안"
              name="boardImprove"
              v-model="data.boardImprove">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!--기대효과-->
            <c-textarea
              :editable="editable"
              :rows="4"
              label="기대효과"
              name="boardBenefit"
              v-model="data.boardBenefit">
            </c-textarea>
          </div>
        </template>
      </c-card>
      <c-dialog :param="popupOptions"></c-dialog>
      <br/>
      <br/>
      <br/>
      <br/>
      <div class="popup-bottom-bar">
        <div class="popup-bottom-bar-btngroup">
          <q-btn-group outline >
            <c-btn
              flat
              :url="saveUrl"
              :isSubmit="isSave"
              :param="data"
              :mappingType="mappingType"
              label="저장"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </div>
        <div class="popup-bottom-bar-close">
          <q-btn flat color="gray" icon="close" @click="closePopUps" />
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'saf-opinion-board-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        boardId: '',
      }),
    },
  },
  data() {
    return {
      attachInfoRequest: {
        isSubmit: '',
        taskClassCd: 'SOB_BOARD_REQUEST',
        taskKey: '',
      },
      saveUrl: transactionConfig.sai.sob.board.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isOwner: false,
      isdelete: false,
      deletable: false,
      data: {
        boardId: '',
        plantCd: '2',
        boardDate: '',
        boardContents: '',
        boardTitle: '',
        boardTypeCd: null,
        boardProblem: '',
        boardResultContent: '',
        boardImprove: '',
        boardBenefit: '',
        boardLocation: '',
        policyCommentModelList: [],
        regUserId: '',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = true;
      this.data.boardDate = this.$comm.getToday();
      // url setting
      this.detailUrl = selectConfig.sai.sob.board.get.url;
      this.insertUrl = transactionConfig.sai.sob.board.insert.url;
      this.updateUrl = transactionConfig.sai.sob.board.update.url;
      this.deleteUrl = transactionConfig.sai.sob.board.delete.url;
    },
    getDetail() {
    },
    saveData() {
      this.saveUrl = this.insertUrl;
      this.mappingType = 'POST';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',    // 확인
            message: '저장하시겠습니까?', // 저장하시겠습니까?     // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('closePopup', '');  
    },
    randomUserId(num) {
      let characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
      let result = '';
      let charactersLength = characters.length;
      for (let i = 0; i < num; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },
    closePopUps() {
      this.$store.dispatch('LogOut').then(() => {
        window.localStorage.removeItem('defaultPlantCd');
        this.$emit('closePopup');
      })
    },
  }
};
</script>
