var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "안전 의견" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        label: "작성자",
                        name: "regUserId",
                      },
                      model: {
                        value: _vm.data.regUserId,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "regUserId", $$v)
                        },
                        expression: "data.regUserId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        label: "제목",
                        name: "boardTitle",
                      },
                      model: {
                        value: _vm.data.boardTitle,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "boardTitle", $$v)
                        },
                        expression: "data.boardTitle",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        label: "위치",
                        name: "boardLocation",
                      },
                      model: {
                        value: _vm.data.boardLocation,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "boardLocation", $$v)
                        },
                        expression: "data.boardLocation",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        label: "의견게시일",
                        name: "boardDate",
                      },
                      model: {
                        value: _vm.data.boardDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "boardDate", $$v)
                        },
                        expression: "data.boardDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        rows: 4,
                        label: "개요",
                        name: "boardContents",
                      },
                      model: {
                        value: _vm.data.boardContents,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "boardContents", $$v)
                        },
                        expression: "data.boardContents",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        rows: 4,
                        label: "현황 및 문제점",
                        name: "boardProblem",
                      },
                      model: {
                        value: _vm.data.boardProblem,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "boardProblem", $$v)
                        },
                        expression: "data.boardProblem",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        rows: 4,
                        label: "개선방안",
                        name: "boardImprove",
                      },
                      model: {
                        value: _vm.data.boardImprove,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "boardImprove", $$v)
                        },
                        expression: "data.boardImprove",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        rows: 4,
                        label: "기대효과",
                        name: "boardBenefit",
                      },
                      model: {
                        value: _vm.data.boardBenefit,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "boardBenefit", $$v)
                        },
                        expression: "data.boardBenefit",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("div", { staticClass: "popup-bottom-bar" }, [
            _c(
              "div",
              { staticClass: "popup-bottom-bar-btngroup" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c("c-btn", {
                      attrs: {
                        flat: "",
                        url: _vm.saveUrl,
                        isSubmit: _vm.isSave,
                        param: _vm.data,
                        mappingType: _vm.mappingType,
                        label: "저장",
                        icon: "save",
                      },
                      on: {
                        beforeAction: _vm.saveData,
                        btnCallback: _vm.saveCallback,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "popup-bottom-bar-close" },
              [
                _c("q-btn", {
                  attrs: { flat: "", color: "gray", icon: "close" },
                  on: { click: _vm.closePopUps },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }